/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"
import objectPath from "object-path"
import { useHtmlClassService } from "../../../_core/AppLayout"
import { toAbsoluteUrl } from "../../../../_helpers"
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns"
import {
  changeNotificationStatus,
  getGlobalNotificationsApi,
  getPatientSourceFormByIdApi,
  getSingleEventApi,
} from "../../../../../app/modules/Auth/_redux/authCrud"
import { DateConvertFunction } from "../../../../../app/modules/Auth/_redux/commonCode"
import { Link } from "react-router-dom"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux"
import { useHistory } from "react-router-dom"
import { Badge } from "@material-ui/core"

export function UserNotificationsDropdown() {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
    }
  }, [uiService])
  const [notificationData, setNotificationData] = useState([])
  const notificationsRedux = useSelector((state) => state.auth.notifications, shallowEqual)
  const notificationsGlobal = useSelector(
    (state) => state.auth.Global_notification,
    shallowEqual
  )
  const dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    getGlobalNotificationsApi()
      .then((data) => {
      })
      .catch((err) => {
      })
  }, [])

  // to get notification from redux
  useEffect(() => {
    let sorted
    sorted = notificationsGlobal?.sort((a, b) => {
      return a.createdAt - b.createdAt
    })

    if (sorted) {
      setNotificationData(sorted)
    }

  }, [notificationsGlobal])

  // on click on notification
  const notificationClicked = (data) => {
    // this part is to avoid status change on admin click
    dispatch(auth.actions.setCTMS_studyID_action(data.StudyID))
    if (sessionStorage.getItem("is_admin_control") === "true") {
    } else {
      updateStatus(data.id)
    }
    setTimeout(() => {
      if (data.Web_Route === "dashboard") {
        history.push("/dashboard")
      }
      if (data.Web_Route === "My_documents") {
        history.push("/my-documents")
      }
      if (data.Web_Route === "More_details" || data.Web_Route === "Study_calendar") {
        getSingleEventMethod(data.DocID)
      }
      if (data.Web_Route[1] === "t") {
        window.open(data.Web_Route)
      }
      if (data.Web_Route === "MyForm") {
        getPatientSourceForm(data.DocID)
      }
    }, 600)
  }

  // to get single event
  const getSingleEventMethod = (id) => {
    getSingleEventApi(id)
      .then((data) => {
        if (data.data.length > 0) {
          redirectTo(data.data)
        }
      })
      .catch((err) => {
      })
  }

  // redirect method

  const redirectTo = (data) => {
    history.push({
      pathname: "/more-details",
      state: data[0],
    })
  }

  // to change status of notifications
  const updateStatus = async (id) => {
    await changeNotificationStatus(id)
      .then((value) => {
        dispatch(auth.actions.requestGlobal_notification())
      })
      .catch((error) => {
      })
  }

  const convertTimeZone = (data) => {
    const dateAsia = new Date(data).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    })
    let customDate = new Date(dateAsia)
    const dateAsiaTwo = dateAsia.split(",")
    let customday = ("0" + customDate.getDate()).slice(-2)
    let customMonth = ("0" + (customDate.getMonth() + 1)).slice(-2)
    let customYear = customDate.getFullYear()
    return dateAsiaTwo[1] + " | " + customday + "-" + customMonth + "-" + customYear
  }
 
  // GP: Get patient source form details by id
  const getPatientSourceForm = (docid) => {
    const dropdownData = JSON.parse(localStorage.getItem("site_obj"))
    let ctmsHostLink = dropdownData.sites.CTMSHostLink.slice(8)
    let siteIdLocal = localStorage.getItem("SiteID_local")
    let patientCtmsId = localStorage.getItem("PatientCTMSID")
    let portalId = localStorage.getItem("id")

    getPatientSourceFormByIdApi(docid).then((data) => {
      if (data.data?.eSourceForms.length > 0) {
        let formDetail = data.data?.eSourceForms[0]
        history.push("/form-details?CTMSHostLink_=" + ctmsHostLink + "&FormID_=" + formDetail?.FormID + "&is_mob=" + false + "&PatientCTMSID_=" + patientCtmsId + "&SiteID_local_=" + siteIdLocal + "&FormStatus_=" + formDetail?.Status + "&ID_=" + formDetail?.id + "&CreatedAt_=" + formDetail?.AssignDate + "&id=" + portalId)
      }
    }).catch((error) => { })
  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item ">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <Badge
                badgeContent={notificationData?.length}
                color="primary"
                className="msg-icon"
                overlap="rectangular"
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/bell.svg")} />
              </Badge>
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight data-tut="My_top_notification" className="">
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle "
          >
            <>
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary tooltip-show"
                id="kt_quick_notifications_toggle"
              >
                <span
                  className={`Custom-tooltip ${notificationData?.length > 0 ? "" : "hidefooter"}`}
                > New Notifications
                </span>
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <Badge
                    badgeContent={notificationData?.length}
                    color="primary"
                    className="msg-icon"
                    overlap="rectangular"
                  >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/bell.svg")} />
                  </Badge>
                </span>
                <span className={`${notificationData?.length > 0 ? "pulse-ring " : "display-none"}`}></span>
                <span className={`${notificationData?.length > 0 ? "pulse-ring " : "display-none"}`} />
              </div>
            </>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg notification-form-wrap background-white">
            <form className="notification-form">
              <div className="notification-dropdown">
                {notificationData?.map((data, index) => {
                  return [
                    <React.Fragment key={index}>
                      <div
                        className="notify-inner"
                        key={index}
                        onClick={() => {
                          notificationClicked(data)
                        }}
                      >
                        <img
                          src={toAbsoluteUrl("/media/svg/icons/Code/bell-dummy.svg")} alt="unable to load"
                        />
                        <div className="notify-content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.Description,
                            }}
                          />
                          <span>
                            <b>{data.eventName}</b>
                          </span>
                          <br />
                          <p style={{ textAlign: "right" }}>
                            {" "}
                            {DateConvertFunction(data.updatedAt, 5)}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>,
                  ]
                })}
                {notificationData?.length > 0 ? (
                  <div className="notify-see">
                    <Link to="/notification">See All</Link>
                  </div>
                ) : (
                  <div className="notify-inner no-notications">
                    <img src={toAbsoluteUrl("/media/svg/icons/Code/bell-dummy.svg")} alt="unable to load" />
                    <div className="notify-content">
                      <p>No New Notifications</p>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}
