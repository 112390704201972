/* eslint-disable eqeqeq */
import axios from "axios"
export const loginUrl = `/api/auth/login/`
export const requestPasswordUrl = `/api/auth/forgotpasswordSentMail`
export const resetPasswordUrl = `/api/auth/forgotPasswordReset`
export const resetEmailUrl = `/api/patient/updateEmail/`
export const regionChangeEmail = `/api/patient/authenticateEmail/`
export const getUserinfoUrl = `/api/patient/GetPatientDetailsByID/`
export const countryUrl = `/api/country/findall/`
export const stateUrl = `/api/state/`
export const cityUrl = `/api/city/`
export const auditTrail = `/api/patient/GetAuditTrails/`
export const adminViewDataDropdown = `/api/dashboard/fetchAllSitesForAdminDashboard/`
export const adminViewListOfAdmin = `/api/dashboard/fetchAllAdmins/`
export const adminViewDataDashboard = `/api/dashboard/fetchAllStudiesForAdmin`
export const registerUrl = `/api/auth/register`
export const cityUrlAll = `/api/city/findall`
export const updateProfileUrl = `/api/patient/updateprofile/`
export const rejectInvite = `/api/dashboard/studydeclineupdatestatus`
export const updateProfileImgUrl = `/api/patient/upload/`
export const updateTicketUrl = `/api/ticket/AddTicket`
export const updatePassword = `/api/auth/changePassword/`
export const updateEmail = `/api/auth/EmailAuthenticateSendMail`
export const getAllTickets = `/api/ticket/getTickets/`
export const getQuickSearch = `/api/calendarevent/findSearchResults`
export const getCalandarData = `/api/calendarevent/getCalendarEvents`
export const getSingleEvent = `/api/calendarevent/findEventById/`
export const webAppLoggedInFlagUrl = `/api/auth/updateIsLoggedFlag/`
export const webAppNotificationFlagUrl = `/api/mark-notification-flag/`
export const documentViewFlag = `/api/subjectsharedocument/updateViewStatus`
export const upcomingEventUrl = `/api/calendarevent/findAllEvents`
export const dashboardInviteApi = `/api/dashboard/findAllDashboard/`
export const dashboardInviteAcceptRejectApi = `/api/dashboard/studyStatusUpdate`
export const getallNotification = `/api/getAllNotification`
export const getGlobalNotification = `/api/getAllNotificationWithoutStudy`
export const getPushNotificationUrl = `/api/calendarevent/fetchInAppEventNotifications`
export const clearPushNotification = `/api/auth/logout/`
export const ctmsVideocallAutheticate = `/api/calendarevent/AuthenticateCTMSUserForTwilioCall`
export const ctmsForm = `/api/patient/getPatienteSourceForm`
export const createAdmin = `/api/auth/createAdmin/`
export const changeMedStatus = `/api/medicationtreatment/updateMedicationsStatus`
export const notificationStatus = `/api/mark-read/`
export const docEdit = `/api/subjectsharedocument/docsupload`
export const docEditWithoutFile = `/api/subjectsharedocument/updateSharedDocumentTitle`
export const createDeviceInfoUrl = `/api/auth/createDeviceInfo`
export const twilioCallEndStatus = `/api/calendarevent/validatePatientToJoinTwilio`
export const adminLoginToPatient = `/api/auth/adminLoggedinIntoPatientaccount`
export const initiateCallForatient = `/api/calendarevent/initiateCallForPatient`
export const activeDeactieAdmin = `/api/dashboard/activeDeactiveAdmin`
export const registerForOtherRegion = `/api/auth/updateEmailForAllRegions`
export const checkUserAvalble = `/api/auth/regionChangeCheckAccountExist`
export const clearPushNotificationUrl = `/api/auth/pushNotificationOff`
export const updateFormStat = `/api/patient/updatePatienteSourceFormStatus`
export const feedbackLogo = `/api/patient/siteDetails`
export const verifyPasswordUrl = `/api/auth/eSignAuthentication/`
export const esourceDetails = '/api/patient/getFormUsingCTMSDetails'
export const getTocken = '/api/auth/getCTMSSessionKey/'
export const getPatientSourceForm = '/api/patient/getPatienteSourceFormByID/'
export const formLinkValidate = '/api/patient/validateeFormLink'
export const getDocCtms = "/api/v1/econsent/patient-portal"
export const migrateData = "/api/migration/migratePatienteSourceForm"
let CryptoJS = require("crypto-js");

export function login(Email, Password_, GoogleID, FacebookID) {
  let Password = CryptoJS.AES.encrypt(Password_, 'dynamisch-secret').toString();
  const data = {
    Email: Email,
    Password: Password,
    GoogleID: GoogleID,
    FacebookID: FacebookID,
    isMobileLogin: "false"
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + loginUrl, data)
}
export function register(
  FirstName,
  LastName,
  Email,
  Password,
  CountryID,
  Phone,
  CountryCodeID,
) {
  const data = {
    FirstName: FirstName,
    LastName: LastName,
    Phone: Phone,
    Email: Email,
    Password: CryptoJS.AES.encrypt(Password, 'dynamisch-secret').toString(),
    CountryID: CountryID,
    CountryCodeID: CountryCodeID,
    isMobileLogin: "false"
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + registerUrl, data)
}
// reset password gettingtoken
export function requestPassword(Email, host) {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + requestPasswordUrl, { Email, host })
}
//  restpassword
export function resetPassword(ResetToken, Password, ID, Url) {
  const data = {
    id: ID,
    ResetToken: ResetToken,
    Password: CryptoJS.AES.encrypt(Password, 'dynamisch-secret').toString(),
    isMobileLogin: "false"
  }
  return axios.post(Url + resetPasswordUrl, data)
}
// geting user data by id
export function getUserByToken(id_, PatientCTMSSiteID_) {
  // Authorization head should be fulfilled in interceptor.
  //we are using this to overcome admin login to other users account
  let idFromStorage
  let siteIdFromStorage
  sessionStorage.getItem("id") ? idFromStorage = sessionStorage.getItem("id") : idFromStorage = localStorage.getItem("id")
  sessionStorage.getItem("site_id") ? siteIdFromStorage = sessionStorage.getItem("site_id") : siteIdFromStorage = localStorage.getItem("site_id")

  // when admin takes controls
  let data
  if (sessionStorage.getItem("is_admin_control") === "true") {
    idFromStorage = localStorage.getItem("id")
    siteIdFromStorage = localStorage.getItem("site_id")
    data = {
      id: localStorage.getItem("id"),
      PatientCTMSSiteID: localStorage.getItem("site_id")
    }
  }
  else {
    data = {
      id: idFromStorage ? idFromStorage : id_,
      PatientCTMSSiteID: siteIdFromStorage ? siteIdFromStorage : PatientCTMSSiteID_,
    }
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getUserinfoUrl, data)
}

// 23-12-2021 : Create device info api
export function createDeviceInfo(data) {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + createDeviceInfoUrl, data)
}
//  country api
export function getCountries() {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + countryUrl)
}
// state api
export const getState = async (id) => {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + stateUrl + `${id}`)
}
// city api
export const getCity = async (id) => {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + cityUrl + `${id}`)
}

// update pro img
export function updateProfileImg(file) {
  const data = new FormData()
  data.append("id", sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"))
  data.append("file", file)
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + updateProfileImgUrl, data)
}

//update password
export function updatePasswordMethod(currentPassword, cPassword) {
  const data = {
    pid: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
    oldPassword: CryptoJS.AES.encrypt(currentPassword, 'dynamisch-secret').toString(),
    newPassword: CryptoJS.AES.encrypt(cPassword, 'dynamisch-secret').toString(),
    isMobileLogin: "false"
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + updatePassword, data)
}

export function studyApiInfo(SessionKey, dropdown_Selected_data, PatientId) {
  const url_2 = dropdown_Selected_data.sites.CTMSHostLink
  const pcid = PatientId ? PatientId : localStorage.getItem("PatientCTMSID")

  return axios.get(
    url_2 +
    "/api/v1/patient-portal/study-info?Key=" +
    SessionKey +
    "&StudyID=" +
    dropdown_Selected_data.CTMSStudyID +
    "&PatientID=" +
    pcid
  )

}

// dashboard invite api
export function dashboardInviteApiMethod() {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + dashboardInviteApi + localStorage.getItem("id"))
}
// accept or reject invite
export function studyInviteAcceptReject(study_id, status) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: study_id,
    Status: status,
    Email: localStorage.getItem("Email_address"),
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + dashboardInviteAcceptRejectApi, data)
}

// reactTour flag toggle dashboard
export function ToggleWebLoggedFlag() {
  const data = {
    id: localStorage.getItem("id"),
    WebAppLoggedInFlag: "false",
    MobileAppLoggedInFlag: "",
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + webAppLoggedInFlagUrl, data)
}
// reactTour flag toggle notification
export function webNotificationFlagToggle() {
  const data = {
    id: localStorage.getItem("id"),
    WebAppNotificationFlag: "false",
    MobileAppNotificationFlag: "",
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + webAppNotificationFlagUrl, data)
}

// ctms register status
export function ctmsRegisterStats(sessionkey, datas) {
  const url = datas.CTMSHostLink
  const patientId = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/patient-portal/update-registration-status?Key=" +
    sessionkey +
    "&PatientID=" +
    patientId +
    "&Status=" +
    2
  )
}

// ctms invite status
export function ctmsInviteStatus(sessonkey, datas, status) {
  const patientId = localStorage.getItem("PatientCTMSID")
  const url = datas.sites.CTMSHostLink
  const studyid = datas.CTMSStudyID
  return axios.get(
    url +
    "/api/v1/patient-portal/update-study-invite-status?Key=" +
    sessonkey +
    "&PatientID=" +
    patientId +
    "&StudyID=" +
    studyid +
    "&Status=" +
    status
  )
}

// get notifications for study
export function getallNotificationsApi() {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getallNotification, data)
}

// get Global notifications
export function getGlobalNotificationsApi() {
  const data = {
    PatientID: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
    StudyID: null,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getGlobalNotification, data)
}

// notifications status change

export function changeNotificationStatus(id) {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + notificationStatus + id)
}

// edit shared doc without file
export function editDocumentApi(id, title, desc, url_doc) {
  const data = {
    DocID: id,
    Title: title,
    Description: desc,
    SiteID: localStorage.getItem("SiteID_local"),
    StudyID: localStorage.getItem("StudyID_local"),
    CTMSStudyID: localStorage.getItem("CTMSStudyID"),
    PatientCTMSID: localStorage.getItem("PatientCTMSID"),
    fullUrl: url_doc,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + docEditWithoutFile, data)
}

// get CTMS document
export const getDocumentCtmsApi = async (key) => {
  const url = localStorage.getItem("CTMSHostLink")
  if (key == undefined) return
  return axios.post(url + getDocCtms + "?Key=" + key)
}

// download  view ctms document
export const viewDownloadDocumentCtmsApi = async (key, doc_id) => {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(
    url + "/api/v1/documents/download?Key=" + key + "&DocumentID=" + doc_id
  )
}

// documet view status
export function DocumentViewFlagApi(id, type) {
  const data = {
    DocID: id,
    FormName: type,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + documentViewFlag, data)
}

// upcoming event dashboard page

export function upcomingEventsApi(id, study_id) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
    SiteID: localStorage.getItem("SiteID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }

  return axios.post(baseurl + upcomingEventUrl, data, config)
}

//update email address
export function updateEmailMethodApi(email, host) {
  const dropdownData = JSON.parse(localStorage.getItem("site_obj"))
  const dropdownDataAdmin = JSON.parse(sessionStorage.getItem("dropdown_value"))
  const patientName = (localStorage.getItem("PatientName"))
  let data

  if (dropdownData || dropdownDataAdmin) {
    if (dropdownData) {
      data = {
        id: localStorage.getItem("id"),
        Email: email,
        CTMSHostLink: dropdownData.sites.CTMSHostLink,
        CTMSUsername: dropdownData.sites.CTMSUsername,
        CTMSPassword: dropdownData.sites.CTMSPassword,
        PatientName: patientName,
        host: host,
        SiteID: localStorage.getItem("SiteID_local")

      }
    }
    // if admin is present  then this block will execute ,as payload parameters are not present for admin

    if (dropdownDataAdmin) {
      data = {
        id: sessionStorage.getItem("id"),
        Email: email,
        CTMSHostLink: dropdownDataAdmin.CTMSHostLink,
        CTMSUsername: dropdownDataAdmin.CTMSUsername,
        CTMSPassword: dropdownDataAdmin.CTMSPassword,
        PatientName: sessionStorage.getItem("PatientName"),
        host: host,
        SiteID: dropdownDataAdmin?.id,
      }
    }
  } else {
    data = {
      id: sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
      Email: email,
      CTMSHostLink: "null",
      CTMSUsername: "null",
      CTMSPassword: "null",
      PatientName: sessionStorage.getItem("PatientName") ? sessionStorage.getItem("PatientName") : patientName,
      SiteID: dropdownDataAdmin?.id ? dropdownDataAdmin?.id : localStorage.getItem("SiteID_local")
    }
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + updateEmail, data)
}

//  restemail
export function resetEmailApi(id, mail, key, Url) {
  const mail_witout_space = mail.replace(/\s+/g, "+")
  return axios.get(Url + resetEmailUrl + id + "/" + mail_witout_space + "/" + key)
}

// get  Get_stipend
export const getStipendApi = async (key) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const StudyID = localStorage.getItem("CTMSStudyID")
  return axios.get(
    url +
    "/api/v1/patient-portal/global-search?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    StudyID
  )
}
// get  Get_stipend for quick search

export const getStipendQuicksearchApi = async (key, searchvalue) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const StudyID = localStorage.getItem("CTMSStudyID")
  return axios.get(
    url +
    "/api/v1/patient-portal/global-search?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    StudyID +
    "&searchText=" +
    searchvalue
  )
}

// medication status
export function changeMedicationStatusApi(id) {
  const data = {
    id: id,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + changeMedStatus, data)
}

// get calandar events
export function getCalandarDataapi() {
  let baseurl = sessionStorage.getItem("end_point")
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
  }
  return axios.post(baseurl + getCalandarData, data)
}

// get  Get_stipend total 

export const getStipendAmountApi = async (key) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url + "/api/v1/patient-portal/rpay-available-balance?PatientID=" + PatientID + "&Key=" + key
  )
}
// stipen total study specific
export const getStipendAmountStudySpecific = async (key, study_id) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url + "/api/v1/patient-portal/rpay-available-balance?PatientID=" + PatientID + "&Key=" + key
  )
}

// get   total visit
export const getTotalVisitApi = async (key, data) => {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID = localStorage.getItem("PatientCTMSID")
  const studyid = data.CTMSStudyID
  return axios.get(
    url +
    "/api/v1/patient-portal/total-ending-status-visits?Key=" +
    key +
    "&PatientID=" +
    PatientID +
    "&StudyID=" +
    studyid
  )
}

// get single calender event
export const getSingleEventApi = async (id, key, ctmsHostLink, type) => {
  let baseurl = sessionStorage.getItem("end_point")
  const value = {
    id: id,
    CTMSHostLink: ctmsHostLink ? ctmsHostLink : sessionStorage.getItem("ctms_host_link") ? sessionStorage.getItem("ctms_host_link") : localStorage.getItem("CTMSHostLink"),
    SessionKey: key ? key : sessionStorage.getItem("sessionKeyValue"),
  }
  const data = axios.post(baseurl + getSingleEvent, value)
  if (type !== 1) {
    data.then(data => { sessionStorage.setItem("callStartDate", new Date(data.data[0].TwilioStartTime)) })

  }
  return data
}

// get quick search result

export function quickSearchApi(text) {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local"),
    SiteID: localStorage.getItem("SiteID_local"),
    searchedText: text,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getQuickSearch, data)
}

//to generate twilio token
export const twilioTokenApi = async (roomname, username, key, room_sid) => {
  let startUrl = sessionStorage.getItem("ctms_host_link")
  // eslint-disable-next-line no-self-assign
  startUrl ? startUrl = startUrl : startUrl = localStorage.getItem("CTMSHostLink")
  sessionStorage.removeItem("ctms_host_link")
  return axios.get(
    startUrl + "/api/v1/patient-portal/get-access-token?Key=" + key + "&RoomName=" +
    roomname +
    "&identity=" +
    username + "&RoomSID=" + room_sid
  )


}
// to create twilio room
export const twilioRoomApi = async (roomname, key, CTMSEventID) => {
  let startUrl
  sessionStorage.getItem("ctms_host_link") ? startUrl = sessionStorage.getItem("ctms_host_link") : startUrl = localStorage.getItem("CTMSHostLink")
  return axios.get(
    startUrl + "/api/v1/patient-portal/create-twillio-video-room?Key=" + key + "&RoomName=" +
    roomname + "&CTMSEventID=" + CTMSEventID
  )
}
// to submit visit feedback
export function feedbackVisitApi(Feedback, FeedbackText, FeedbackID, url) {
  const url_ = url + "/api/v1/patient-portal/save-patient-visit-feedback"
  const params = new URLSearchParams()
  params.append("Feedback", Feedback)
  params.append("FeedbackText", FeedbackText)
  params.append("FeedbackID", FeedbackID)
  return axios.post(url_, params)
}

// get push notifications
export function getPushNotification() {
  const data = {
    PatientID: localStorage.getItem("id"),
    StudyID: localStorage.getItem("StudyID_local")
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getPushNotificationUrl, data)
}

// logout api to clear token of push notification
export function clearPushNotificationApi(timeout, adminid) {
  const data = {
    PatientID: localStorage.getItem("id"),
    Token: localStorage.getItem("PushToken"),
    Timeout: timeout ? timeout : "false",
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + clearPushNotification, data)
}

// ctms video call authenticate api
export function ctmsVideocallAutheticateApi(PatientID, EventID, AuthToken, baseurl_) {
  const data = {
    "PatientID": PatientID,
    "EventID": EventID,
    "AuthToken": AuthToken
  }
  let baseurl = sessionStorage.getItem("end_point")
  if (baseurl_) {
    baseurl = baseurl_
  }
  return axios.post(baseurl + ctmsVideocallAutheticate, data)
}

// get carousel img data
export function getCarouselImgApi(url_for_api) {
  const url = url_for_api
  return axios.get(url)
}

// ctms form list
export function getCtmsFormlistApi() {
  const data = {
    "PatientID": localStorage.getItem("id"),
    "StudyID": localStorage.getItem("StudyID_local"),
  }
  let baseurl = sessionStorage.getItem("end_point")

  return axios.post(baseurl + ctmsForm, data)
}


// ctms on click of form get form details
export function ctmsSingleFormApi(sessionkey, FormID, datas) {
  const url = datas.CTMSHostLink
  const PatientId = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms?Key=" +
    sessionkey +
    "&PatientID=" +
    PatientId +
    "&FormID=" +
    FormID
  )
}
// ctms form done api
export function doneFormChangesApi(sessionkey, datas) {
  // const url = datas.sites.CTMSHostLink
  const url = datas.CTMSHostLink
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  const params = new URLSearchParams()
  params.append("FormID", localStorage.getItem("FormID"))
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("Type", 1)
  params.append("Key", sessionkey)
  return axios.post(url + "/api/v1/forms/patients/complete", params, config)
}

// ctms form save form
export function SaveChangesFormApi(sessionkey, formvalue) {
  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  let form_value = formvalue
  form_value.manualProviders = {}
  const data = {
    "force": false,
    "ArmID": localStorage.getItem("armId"),
    "extraSaveProps": {},
    "PatientID": localStorage.getItem("PatientCTMSID"),
    "CurrentFormPageID": sessionStorage.getItem("current_page_id"),
    "FormID": localStorage.getItem("FormID"),
    formData: form_value,
    "formSessionWindowUUID": sessionStorage.getItem("window_id"),
  }
  const data2 = new FormData()
  data2.append("PatientID", localStorage.getItem("PatientCTMSID"))
  data2.append("FormID", localStorage.getItem("FormID"))
  data2.append("formData", form_value)
  return axios.post(url + "/api/v1/forms/submissions?Key=" + sessionkey, data, config)
}

// ctms upload file type 
export function uploadFiletypeApi(sessionkey, base64, file) {

  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("Name", file.name)
  params.append("DataString", base64)
  params.append("Type", "generic")
  return axios.post(url + "/api/v1/files/upload", params, config)
}

// ctms delete file type 
export function DeleteFiletype(sessionkey, hash) {
  const url = localStorage.getItem("CTMSHostLink") + "/api/v1/patient-portal/delete-document"
  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("Hash", hash)

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  }

  return axios.post(url, params, config)
}

// get saved values of ctms form
export function getSavedFormsApi(sessionkey) {
  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  const FormID = localStorage.getItem("FormID")
  const PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(url + "/api/v1/forms/data?Key=" + sessionkey + "&FormID=" + FormID + "&PatientID=" + PatientID + "&CreateDefault=" + 1, config)
}

// get saved images of ctms form
export function getSavedImagesApi(sessionkey, Hash) {
  const url = localStorage.getItem("CTMSHostLink")
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    responseType: "arraybuffer"
  }
  return axios.get(url + "/api/v1/files?Key=" + sessionkey + "&Hash=" + Hash, config)

}

// get saved images of ctms form
export function getDropdownValueCtmsformApi(sessionkey, url_end_point) {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(url + url_end_point + "?Key=" + sessionkey)

}

// get ctms form country list
export const ctmsCountryListApi = async () => {
  const url = localStorage.getItem("CTMSHostLink")
  const endurl = "/api/v1/countries"
  return axios.get(url + endurl)
}

// get ctms user info like hostlink usernme
export const getCtmsUserInfoApi = async (siteid) => {
  const url = sessionStorage.getItem("end_point")
  const endurl = "/api/dashboard/getSiteDetails/" + siteid
  return axios.get(url + endurl)
}

// api when invites get reject
export function rejectInviteStatusApi(PatientCTMSID, PatientCTMSSiteID) {
  const data = {
    "PatientID": localStorage.getItem("id"),
    "PatientCTMSID": PatientCTMSID,
    "PatientCTMSSiteID": PatientCTMSSiteID
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.put(baseurl + rejectInvite, data)
}

// get audit trail
export const auditTrailApi = async () => {
  const id = localStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + auditTrail + `${id}`)
}
// get admin view dropdown

export const adminViewDropdownApi = async () => {
  const id = sessionStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + adminViewDataDropdown + `${id}`)
}

// get admin list
export const getAdminListApi = async () => {
  const id = sessionStorage.getItem("id")
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + adminViewListOfAdmin + `${id}`)
}
// toget admin view dashboard data
export const adminViewDashboardApi = async (SiteID) => {
  const data = {
    id: sessionStorage.getItem("id"),
    SiteID: SiteID
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + adminViewDataDashboard, data)
}

// twilio biling api
export function twilioBillingApi(key, sid, date_type) {
  let startUrl = sessionStorage.getItem("ctms_host_link")
  // eslint-disable-next-line no-self-assign
  startUrl ? startUrl = startUrl : startUrl = localStorage.getItem("CTMSHostLink")
  const url = startUrl
  let user_id = 0
  if (sessionStorage.getItem("is_ctms_user")) {
    user_id = 1
  }

  const params = new URLSearchParams()
  params.append("UserID", localStorage.getItem("PatientCTMSID"))
  params.append("EventID", localStorage.getItem("CTMSEventID"))
  params.append("RoomSID", sid)
  params.append("StudyID", localStorage.getItem("CTMSStudyID"))
  params.append("ToBeLogged", date_type)
  params.append("UserType", user_id)
  return axios.post(url + "/api/v1/patient-portal/track-virtual-visit-usage?Key=" + key, params)

}
// to change ststus of twilio call end
export const twilioCallEndStatusChangeApi = () => {
  const data = {
    EventID: localStorage.getItem("join_status_id"),
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + twilioCallEndStatus, data)
}

// Funtion to Login the admin into patient account
export const adminLoginToPatientAccountApi = (patientEmail) => {
  const data = {
    id: sessionStorage.getItem("id"),
    Email: patientEmail
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + adminLoginToPatient, data)
}

// Funtion to initiate call for patient from admin
export const initiateCallApi = (PatientID, eventid, baseurl_, room_sid) => {
  const data = {
    EventID: eventid,
    RoomSID: room_sid
  }
  let baseurl = sessionStorage.getItem("end_point")
  if (baseurl_) {
    baseurl = baseurl_
  }

  return axios.post(baseurl + initiateCallForatient, data)
}

// to make user admin 

export function createAdminApi(Email, FirstName, LastName) {
  const data = {
    "Email": Email,
    "SiteID": sessionStorage.getItem("PatientCTMSSiteID"),
    "FirstName": FirstName,
    "LastName": LastName

  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + createAdmin, data)
}

// to activate or deactivate admin by superadmin
export function activeDeactieAdminApi(superid, id, flag) {
  const data = {
    "id": superid,
    "AdminID": id,
    "acitveFlag": flag

  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + activeDeactieAdmin, data)
}

// get esign dropdown
export function getEsignDropdown(sessionkey) {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(url + "/api/v1/forms/signature-statements?Key=" + sessionkey)
}

// get submit esign 
export function esignSubmitApi(Statement, current_obj, key, form_value, rid, username, password) {
  const url = localStorage.getItem("CTMSHostLink")
  const formValue = form_value
  let InputListRowID = ""
  let commonId = rid ? rid : formValue[current_obj?.Parent]?.[0]
  if (commonId) {
    InputListRowID = commonId
  }
  const params = new URLSearchParams()
  params.append("UserName", username)
  params.append("Statement", Statement)
  params.append("ElementID", current_obj.UUID)
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("InputListRowID", InputListRowID)
  params.append("PIN", "")
  params.append("shouldSave", true)
  params.append("Password", password)
  return axios.post(url + "/api/v1/forms/sign?Key=" + key, params)
}

//region change on register page if user is alredy present on differnt server
export function registerRegionChangeApi(email, host) {
  const data = {
    "Email": email,
    "host": host
  }
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + registerForOtherRegion, data, config)
}

//  region change email update
export function resetEmailRegionChangeApi(id, mail, key, Url) {
  return axios.get(Url + regionChangeEmail + id + "/" + mail + "/" + key)
}
// to check if user is present on other region or not
export function checkUserAvailbalityApi(email, host) {
  const data = {
    "Email": email,
  }
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" }
  }
  return axios.post(host + checkUserAvalble, data, config)
}

// to clear push notification when region changes
export function clearPushNotificationOnRegionCahnge(host) {
  const data = {
    "PatientID": localStorage.getItem("id"),
    "Token": localStorage.getItem("PushToken"),
  }
  return axios.post(host + clearPushNotificationUrl, data)
}

// GP: This function calls the api to check the form status
export function getFormStatusApi(sessionkey, data, formID) {
  const url = data.CTMSHostLink
  let PatientID = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms/pages/status?Key=" +
    sessionkey +
    "&PatientID=" +
    PatientID +
    "&FormID=" +
    formID
  )
}

// GP: This function calls the api to update the form status
export function updateFormStatusApi(patientId, id) {
  let baseurl = sessionStorage.getItem("end_point")
  const data = {
    "PatientID": patientId,
    "id": id
  }

  return axios.post(baseurl + updateFormStat, data)
}

// to get log for feedback screen
export function getFeedbackLogoApi(host, SiteID) {
  const data = {
    "host": host,
    "SiteID": SiteID,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + feedbackLogo, data)
}

// to verify password // for esign
export function verifyPasswordAPi(password) {
  let PasswordVlaue = CryptoJS.AES.encrypt(password, 'dynamisch-secret').toString();
  let queryParams = new URLSearchParams(window.location.search)
  const data = {
    "id": queryParams.get("is_mob") === "true" ? queryParams.get("id") : localStorage.getItem("id"),
    "Password": PasswordVlaue,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + verifyPasswordUrl, data)
}

// to get esign parameter when click on email invites
export function getEsourceParameterApi(CTMSStudyID, CTMSSiteID, CTMSHostLink, CTMSFormID) {
  const link = "https://" + CTMSHostLink
  const data = {
    "CTMSStudyID": CTMSStudyID,
    "CTMSSiteID": CTMSSiteID,
    "CTMSHostLink": link,
    "CTMSFormID": CTMSFormID
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + esourceDetails, data)
}
// To get ctms sessionkey  // new way

export function getCtmsTokenApi(site_ids) {
  let baseurl = sessionStorage.getItem("end_point")
  const site_id = site_ids ? site_ids : sessionStorage.getItem("SiteID_local") ? sessionStorage.getItem("SiteID_local") : localStorage.getItem("SiteID_local")
  const portal_id = sessionStorage.getItem("id_admin") ? sessionStorage.getItem("id_admin") : localStorage.getItem("id")
  return axios.get(baseurl + getTocken + site_id + "/" + portal_id)
}

//to check status of ctms esource form // lock or unlock
export function esourceFormStatusApi(sessionkey, FormID_) {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientID_ = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms/sessionlocks?Key=" +
    sessionkey +
    "&FormID=" + FormID_ +
    "&PatientID=" +
    PatientID_ +
    "&Active=" + 1
  )
}

// to unlock esource form
export function unlockEsourceFormApi(sessionkey, PageID) {
  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("PageID", sessionStorage.getItem("current_page_id"))
  params.append("PatientID", localStorage.getItem("PatientCTMSID"))
  params.append("force", "1")
  params.append("MyStudyManager", 1)

  let startUrl = localStorage.getItem("CTMSHostLink")
  const url_ = startUrl + "/api/v1/forms/sessionlock/unlock"
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  return axios.post(url_, params, config)

}

// GP: Get patient source form by ID
export function getPatientSourceFormByIdApi(formid) {
  let baseurl = sessionStorage.getItem("end_point")
  return axios.get(baseurl + getPatientSourceForm + formid)
}

//to check status of ctms esource page // lock or unlock
export function getEsourcePageStatusApi(sessionkey, pageid, windowid) {
  const url = localStorage.getItem("CTMSHostLink")
  const PatientId = localStorage.getItem("PatientCTMSID")
  return axios.get(
    url +
    "/api/v1/forms/sessionlock?Key=" +
    sessionkey +
    "&PageID=" + pageid +
    "&PatientID=" + PatientId +
    "&WindowID=" + windowid +
    "&OnDemand=" + 1 +
    "&MyStudyManager=" + 1
  )
}

// get Session data
export function getEsourceSessionDataApi(sessionkey, id) {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(
    url +
    "/api/v1/forms/sessionlock?Key=" + sessionkey +
    "&ID=" + id
  )
}

// unlock esource session by window id
export function unlockEsourceById(sessionkey, id, window_id) {
  const params = new URLSearchParams()
  params.append("Key", sessionkey)
  params.append("ID", id)
  params.append("WindowID", window_id)
  let start_url = localStorage.getItem("CTMSHostLink")
  const url_ = start_url + "/api/v1/forms/sessionlock/unlock"

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }
  return axios.post(url_, params, config)
}


// to validate form link // coming from mobile
export function validateFormLinkApi(FormID, PatientID, Token) {
  const data = {
    "FormID": FormID,
    "PatientID": PatientID,
    "Token": Token,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + formLinkValidate, data)
}

// to migrateData data on study accept
export function migrateDataTo(data, StudyID) {
  const info = {
    "eSource": data.eSource,
    "PatientID": localStorage.getItem("id"),
    "StudyID": StudyID,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + migrateData, info)
}

// geting user data by id
export function getUserByTokenForInvites(id_, PatientCTMSSiteID_) {
  const data = {
    id: id_,
    PatientCTMSSiteID: PatientCTMSSiteID_,
  }
  let baseurl = sessionStorage.getItem("end_point")
  return axios.post(baseurl + getUserinfoUrl, data)

}

// to get twilio user count
export const getTwilioUserCount = async (key, roomName) => {
  const url = localStorage.getItem("CTMSHostLink")
  return axios.get(
    url + "/api/v1/patient-portal/get-active-participants?Key=" + key + "&RoomName=" + roomName
  )
}