/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, } from "react"
import { Link } from "react-router-dom"
import { useFormik } from "formik"
import { toAbsoluteUrl, getPushToken } from "../../../../_common/_helpers"
import * as Yup from "yup"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { login, updatePasswordMethod } from "../_redux/authCrud"
import { DialogActions, DialogTitle, Dialog, DialogContent as MuiDialogContent, Icon, withStyles } from '@material-ui/core';
import SVG from "react-inlinesvg"
import { createDeviceInfo } from './../_redux/authCrud';
import { DateConvertFunction, getBroserName } from "../_redux/commonCode"

const initialValues = {
  email: "",
  password: "",
  region: "",
}

function Login(props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState(false)
  const [passwordExpDate, setpasswordExpDate] = useState("")
  const [errorMessageForConnection, seterrorMessageForConnection] = useState("Error")
  const [hideFirstSection, sethideFirstSection] = useState(false)
  const [feedbackPopup, setfeedbackPopup] = useState(false)
  const [errorPopupStatus, seterrorPopupStatus] = useState(false)
  const [popupMessage, setpopupMessage] = useState("")
  const [Authtoken, setAuthtoken] = useState("")
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [selectedOptionRegion, setselectedOptionRegion] = useState([{
      value: "0",
      label: "Region",
    }])
  const [countryNmae, setcountryName_] = useState([{
    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="img" />United States </div>,
  }, {
    value: "https://ca.mystudymanager.com",
    label: <div><img src="/media/misc/canada.svg" height="30px" width="30px" alt="img" />Canada</div>,
  }])
  let CryptoJS = require("crypto-js");

  useEffect(() => {
    if (sessionStorage.getItem('password_expired')) {
      const sideImg = document.getElementById('auth-page')
      sethideFirstSection(true)
      if (sideImg) {
        sideImg.classList.add("hidefooter");
      }
    }
    // to show session out popup
    if (sessionStorage.getItem('sesson_logout')) {
      setTimeoutModalOpen(true)
    }

    return () => {
      sessionStorage.removeItem("sesson_logout");
    }
  }, [])
  // colour them
  let colourCode = {
    BrandingColor: '#0C2339',
    AccentColor: '#21B3C4',
    HighLightColor: '#F68723'
  }
  useEffect(() => {
    props.settheme(colourCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    // on hold

    // region: Yup.string()
    //   .required("Region is required")
    //   .nullable()
    //   .test("not blank", "Select Region", (value) => {
    //     return value != "0"
    //   }),

  })

  const loginSchemaResetPass = Yup.object().shape({
    password_: Yup.string().trim()
      .min(8, "Minimum 8 characters required !")
      .max(25, "Maximum 25 characters allowed")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain 8 characters, 1 uppercase, 1 lowercase, 1 number, and one special character."
      ),
    cPassword: Yup.string()
      .required("Password confirmation is required")
      .when("password_", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password_")],
          "Password and Confirm Password didn't match"
        ),
      }),
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }
  // to apply css 
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const getInputClasses2 = (fieldname) => {
    if (formikResetPassword.touched[fieldname] && formikResetPassword.errors[fieldname]) {
      return "is-invalid"
    }

    if (formikResetPassword.touched[fieldname] && !formikResetPassword.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  // 23-12-2021 : Creating device info - called on successfully login
  const deviceInfo = async (access_token) => {
    getPushToken().then((token) => {
      localStorage.setItem("PushToken", token);
      let browserName = getBroserName()
      let data = {
        "PatientID": sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
        "DeviceID": access_token,
        "DeviceINFO": browserName,
        "PushToken": token,
      }

      createDeviceInfo(data).then((result) => { })
    }).catch((err) => { });
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      let encripted = CryptoJS.AES.encrypt(values.password, 'secret').toString();
      setTimeout(() => {
        localStorage.setItem("password_encrypted", encripted)
        login(values.email, values.password, "", "")
          .then((data) => {
            disableLoading()
            localStorage.setItem("PatientCTMSID", data.data.PatientCTMSID)
            localStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)
            localStorage.setItem("Email_address", data.data.Email)
            if (data.data.LogInType === "Admin" || data.data.LogInType === "SuperAdmin") {
              sessionStorage.setItem("id", data.data.id)
              sessionStorage.setItem("site_id", data.data.PatientCTMSSiteID)
              sessionStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)
            }
            else {
              localStorage.setItem("id", data.data.id)
              localStorage.setItem("site_id", data.data.PatientCTMSSiteID)
              deviceInfo(data.data.authToken)
            }
            const sideImg = document.getElementById('auth-page')
            if (data.data.isPasswordExpired) {
              sessionStorage.setItem('password_expired', true);
              sessionStorage.setItem('password_temp', values.password);
              sessionStorage.setItem('password_expired_date', data.data.lastPasswordChangeDate);
              setAuthtoken(data.data.isPasswordExpired)
              sethideFirstSection(true)
              seterrorMessage(false)
              setpasswordExpDate(data.data.lastPasswordChangeDate)
              if (sideImg) {
                sideImg.classList.add("hidefooter");
              }
            } else {
              props.login(data.data.authToken)
            }
          })
          .catch((err) => {
            seterrorMessage(true)
            seterrorMessageForConnection(err.response?.data.message)
          })
          .finally(() => {
            disableLoading()
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  const formikResetPassword = useFormik({
    initialValues: {
      currentPassword: "",
      password_: "",
      cPassword: "",
    },
    validationSchema: loginSchemaResetPass,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let currentPassord = formik.values.password ? formik.values.password : sessionStorage.getItem('password_temp')
      updatePasswordMethod(currentPassord, values.cPassword).then(data => {
        setSubmitting(false); setfeedbackPopup(true); sessionStorage.removeItem("password_expired"); sessionStorage.removeItem("password_temp"); sessionStorage.removeItem("password_expired_date")
      }).catch(error => { setSubmitting(false); seterrorPopupStatus(true); setpopupMessage(error.response.data.message) });
    },
  })

  // popup close
  const handleClosesFeedbackPopup = () => {
    setfeedbackPopup(false)
    props.login(Authtoken)
  }
  // popup close
  const handleClosesErrorPopup = () => {
    seterrorPopupStatus(false)
  }

  // call logout sessions
  const onLogout = () => {
    const sideImg = document.getElementById('auth-page')

    if (sideImg) {
      sideImg.classList.remove("hidefooter");
    }
    sessionStorage.removeItem("password_expired");
    sessionStorage.removeItem("password_temp")
    sessionStorage.removeItem("password_expired_date")
    props.history.push('/logout')
  }
  // const DialogContent = withStyles((theme) => ({
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  const handleLogout = () => {
    sessionStorage.removeItem("sesson_logout");
    setTimeoutModalOpen(false)
  };
  useEffect(() => {
    props.route_status("https://realtimestage.wpengine.com/study-login.php")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Setoptionvalue = (e) => {
    setselectedOptionRegion(e)
    formik.setFieldValue("region", e.value)
    props.end_point(e.value)
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {errorMessage && (
        <div className="alert alert-custom alert-light-danger fade show mb-10" role="alert">
          <div className="alert-icon">
            <span className="svg-icon svg-icon-3x svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>{" "}
            </span>
          </div>
          <div className="alert-text font-weight-bold">{errorMessageForConnection ? errorMessageForConnection : "Error while connecting"}</div>
          <div className="alert-close" onClick={() => seterrorMessage(false)}>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">
                <i className="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
      )}
      {hideFirstSection === false ? <> {/* start:: Aside header */}
        <p className="patient-portal">--PATIENT PORTAL--</p>
        <Link to="/" className="text-center mb-10 mb-lg-20">
          <img alt="Logo" className="login-logo mb-2 " src={toAbsoluteUrl("/media/logos/RealTime_Logo-v2_My Study Manager_Horizontal.png")} />
        </Link>

        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
          {/* on hold  region change part */}

          {/* <div className="mb-7">
            <Select
              value={selectedOptionRegion}
              onChange={(e) => {
                Setoptionvalue(e)
              }}
              options={countryNmae}
              name="region"
              className="region-select-drop mb-0"
            />
            {formik.touched.region && formik.errors.region ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.region}</div>
              </div>
            ) : null}
          </div> */}

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto ${getInputClasses("email")}`}
              name="email"
              maxLength={51}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto ${getInputClasses("password")}`}
              name="password"
              maxLength={51}
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <Link
              to="/auth/forgot-password"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              Forgot Password?
            </Link>
          </div>
        </form>
        {/*end::Form*/}
        <div className="mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 login-left-footer">
          <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
          <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
            Register
          </Link>
          here!
        </div></> : <>
        {/* second part starts */}
        <div className="success-icon user-name-wrap">
          <p className="mb-0 user-name-text"> Hi <span className='user-name'>{localStorage.getItem("PatientName")},</span></p>
          <button className="btn btn-secondary close-btn" onClick={onLogout}> Logout</button>
        </div>
        <h3 className="success-text mb-5 font-weight-bold success-head error-message">
          Your current password has expired. Last password update was on {passwordExpDate ? DateConvertFunction(passwordExpDate, 4) : DateConvertFunction(sessionStorage.getItem("password_expired_date"), 4)}.<Icon className="ml-2 fa fa-exclamation-triangle" />
        </h3>
        <h3 className="success-text mb-10 font-weight-bold success-head error-message">
          Please enter a new password.
        </h3>
        {/* begin::Form */}
        <form onSubmit={formikResetPassword.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form">
            <div className="mb-10">
              <div className="form-group row form-group fv-plugins-icon-container">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  New Password
                </label>
                <div className="col-lg-9 col-xl-9">
                  <input
                    type="password"
                    placeholder="New Password"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses2(
                      "password_"
                    )}`}
                    name="password_"
                    maxLength={51}
                    autoComplete={false}
                    {...formikResetPassword.getFieldProps("password_")}
                  />
                  {formikResetPassword.touched.password_ && formikResetPassword.errors.password_ ? (
                    <div className="invalid-feedback">{formikResetPassword.errors.password_}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Confirm Password
                </label>
                <div className="col-lg-9 col-xl-9">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses2(
                      "cPassword"
                    )}`}
                    name="cPassword"
                    maxLength={51}
                    {...formikResetPassword.getFieldProps("cPassword")}
                  />
                  {formikResetPassword.touched.cPassword && formikResetPassword.errors.cPassword ? (
                    <div className="invalid-feedback">
                      {formikResetPassword.errors.cPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-toolbar d-flex align-items-center justify-content-between mt-10">
                <div className="left-section d-flex align-items-center">
                  <div className='contact-icon '>
                    <img src="/media/misc/email.png" alt="" />
                  </div>
                  <span>
                    <a href={`mailto:success@realtime-ctms.com`} className='m-0 contact-admin-text'>
                      Contact Admin
                    </a>
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={
                    formikResetPassword.isSubmitting || (formikResetPassword.touched && !formikResetPassword.isValid)
                  }
                >
                  Reset Password
                  {formikResetPassword.isSubmitting}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
      }

      {/* Successfully popup */}
      <Dialog onClose={handleClosesFeedbackPopup} aria-labelledby="customized-dialog-title" open={feedbackPopup} className="success-popup">
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">Successful!</h2>
          <p className="success-text mb-10">Your password  successfully updated!</p>
          <button
            type="submit"
            onClick={handleClosesFeedbackPopup}
            className="btn btn-primary mr-5 save-btn"
          >
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Successfully popup  end*/}

      {/* Failed dialog */}
      <Dialog onClose={handleClosesErrorPopup} aria-labelledby="customized-dialog-title" open={errorPopupStatus} className="success-popup">
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-exclamation-triangle" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
          <p className="success-text mb-10">{popupMessage}</p>
          <button
            type="submit"
            onClick={handleClosesErrorPopup}
            className="btn btn-primary mr-5 save-btn"
          >
            Ok
          </button>
        </DialogContent>
      </Dialog>

      {/* session time out popup */}
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={timeoutModalOpen}
          className="log-out-wrap"
        >
          <DialogTitle className="popup-heading text-center">Session Timeout</DialogTitle>
          <DialogContent dividers className="">
            <h4 className="success-text font-weight-bold success-head session-text">
              The current session has expired.
              Please login again.
            </h4>
            <Icon
              className="fa fa-exclamation-triangle"
              style={{
                marginBottom: -4,
                fontSize: 17,
                marginLeft: 5,
                width: 20,
                color: "#ff5858",
              }}
            />
          </DialogContent>
          <DialogActions className="btn-wrapper">
            <div className="card-toolbar">
              <button className="btn btn-secondary close-btn"
                onClick={handleLogout}
              >
                OK
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))