/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from "react"
import { useHtmlClassService } from "../../_core/AppLayout"
import Select from "react-select"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as auth from "../../../../app/modules/Auth"
import { checkUserAvailbalityApi, login, createDeviceInfo, dashboardInviteApiMethod, clearPushNotificationOnRegionCahnge } from "../../../../app/modules/Auth/_redux/authCrud"
import { Dialog, Icon, withStyles, DialogContent as MuiDialogContent } from "@material-ui/core"
import { getPushToken } from "../../../../_common/_helpers"
import { getBroserName } from "../../../../app/modules/Auth/_redux/commonCode"

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export function Footer() {
  const queryParams = new URLSearchParams(window.location.search)
  let isMob = queryParams.get("is_mob")
  let endPointUrl = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const uiService = useHtmlClassService()
  const dispatch = useDispatch()
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const [dummyVar, setDummyVar] = useState(true);
  const [selectedOptionRegion, setSelectedOptionRegion] = useState([{
    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States</div>,
  }])
  const [countryName, setCountryName] = useState([{
    value: "https://dev.mystudymanager.com",
    // value: "https://val.mystudymanager.com",
    label: <div><img src="/media/misc/united-states.svg" height="30px" width="30px" alt="" />United States</div>,
  }, {
    value: "https://ca.mystudymanager.com",
    label: <div><img src="/media/misc/canada.svg" height="30px" width="30px" alt="" />Canada</div>,
  },])

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    }
  }, [uiService])

  // to set dropdown value  of region same as  at the time of login
  useEffect(() => {
    setSelectedOptionRegion(countryName.find(val => { return val.value === endPointUrl }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdownChanged = (e) => {
    setLoadingStatus(true)
    const emailAddress = localStorage.getItem("Email_address")
    checkUserAvailbalityApi(emailAddress, e.value).then(data => { changeRegion(e, data, emailAddress) }).catch(err => {
      setLoadingStatus(false)
    })
  }

  const changeRegion = (e, data, emailAddress) => {
    if (data.data) {
      // if data find then we are  login to that region
      dispatch(auth.actions.end_point(e.value))
      const password_encrypted = localStorage.getItem("password_encrypted")
      let CryptoJS = require("crypto-js");
      var bytes = CryptoJS.AES.decrypt(password_encrypted, 'secret');
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      // this  "setDummyVar"will need otherwise end url is not getting updated when login api gets hit
      setDummyVar(!dummyVar)

      login(emailAddress, originalText, "", "")
        .then((data) => {
          setOpenSuccess(true)

          localStorage.setItem("PatientCTMSID", data.data.PatientCTMSID)
          localStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)
          localStorage.setItem("Email_address", data.data.Email)
          if (data.data.LogInType === "Admin" || data.data.LogInType === "SuperAdmin") {
            sessionStorage.setItem("id", data.data.id)
            sessionStorage.setItem("site_id", data.data.PatientCTMSSiteID)
            sessionStorage.setItem("PatientName", data.data.FirstName + " " + data.data.LastName)
          }
          else {
            localStorage.setItem("id", data.data.id)
            localStorage.setItem("site_id", data.data.PatientCTMSSiteID)
            deviceInfo()
          }
          dispatch(auth.actions.login(data.data.authToken))
          const pathname = window.location.pathname
          if (pathname === "/notification" || pathname === "/dashboard" || pathname === "/my-form") {
            dispatch(auth.actions.requestNotification())
          }
          // to clear push notification of old region
          // clearPushNotificationOnRegionCahnge(selectedOptionRegion.value).then(dat => { console.log("dat", dat) }).catch(err => { console.log("err", err) })
          setSelectedOptionRegion(e)
        })
        .catch((err) => {
          setErrorMessage("Could not change region, Password is different for that region")
          setErrorPopup(true)
          dispatch(auth.actions.end_point(selectedOptionRegion.value))
        }).finally(() => {
          setLoadingStatus(false)
          dashboardInviteApiMethod()
            .then((value) => {
              dispatch(auth.actions.setstudies_action(value.data[0]?.studies))
            })
            .catch((error) => { })
        })
    } else {
      setErrorPopup(true)
      setErrorMessage("User not found in that region.")
      setLoadingStatus(false)
    }

  }
  const deviceInfo = async () => {
    getPushToken().then((token) => {
      localStorage.setItem("PushToken", token);
      let browserName = getBroserName()
      let data = {
        "PatientID": sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
        "DeviceID": sessionStorage.getItem("id") ? sessionStorage.getItem("id") : localStorage.getItem("id"),
        "DeviceINFO": browserName,
        "PushToken": token,
      }
      createDeviceInfo(data).then((result) => {
      })
    }).catch((err) => {
    });
  }

  // popup methods
  const handleClosesError = () => {
    setErrorPopup(false)
  }
  const handleClosesSuccess = () => {
    setOpenSuccess(false)
  }

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div className={`${layoutProps.footerContainerClasses}`}>
        <div className="text-dark region-wrap">
          <span className="text-muted font-weight-bold mr-2"><img className="footer-img" src="/media/misc/footer-icon.png" alt="unable to load" />
            Copyright © 2021 - 2025 | RealTime Participant Portal v1.2.0. | All Rights Reserved
            |{" "}
            <a
              href="
             https://www.realtime-ctms.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            | <a href="https://www.realtime-ctms.com/cookies-policy" target="_blank" rel="noopener noreferrer">Cookies Policy</a>

          </span>
          {/* on hold  region change */}

          {/* {isMob !== "true" && !sessionStorage.getItem("study_id_for_pp") && user?.LogInType === "Patient" && <Select
            value={selectedOptionRegion}
            onChange={(e) => {
              dropdownChanged(e)
            }}
            options={countryName}
            name="region"
            className="region-select-drop"
            menuPlacement="top"
          />}
          {loadingStatus && <span className="ml-3 spinner spinner-white"></span>} */}


        </div>
      </div>
      {/* Failed dialog */}
      <Dialog
        onClose={handleClosesError}
        aria-labelledby="customized-dialog-title"
        open={errorPopup}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-exclamation-triangle" />
          </div>
          <h2 className="font-weight-bold m-10 success-head">ERROR!</h2>
          <p className="success-text mb-10"> {errorMessage}</p>
          <button type="submit" onClick={handleClosesError} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>
        </DialogContent>
      </Dialog>
      {/* Failed dialog end*/}

      {/* Successfully popup */}
      <Dialog
        onClose={handleClosesSuccess}
        aria-labelledby="customized-dialog-title"
        open={openSuccess}
        className="success-popup"
      >

        <DialogContent dividers className="text-center p-10">
          <div className="success-icon">
            <Icon className="fa fa-check icon" />
          </div>
          <p className="success-text mb-10">
            Region changed successfully .
          </p>
          <button type="submit" onClick={handleClosesSuccess} className="btn btn-primary mr-5 save-btn">
            Ok
          </button>
        </DialogContent>
      </Dialog>

    </div>
  )
}
