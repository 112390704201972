/* eslint-disable react/jsx-pascal-case */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_common/layout";
import { AdminLayout } from "../_common/layout/";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/Errors/ErrorsPage";
import AutoLogout from "./AutoLogout";
import { Suspense, lazy } from "react";
const AuthPagePageRoute = lazy(() =>
  import("./modules/Auth/pages/AuthPage")
);
const BasePageRoute = lazy(() =>
  import("./BasePage")
);
const AdminPageRoute = lazy(() =>
  import("./AdminBasePage")
);
export function Routes() {

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const { admin_status } = useSelector(
    ({ auth }) => ({
      admin_status: auth.user?.LogInType,
    }),
    shallowEqual
  );

  return (
    <Suspense>

      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPagePageRoute />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (<>
          {/*AutoLogout  is used  for auto logout  and here we are seprating admin view and patient view */}
          {admin_status === "Patient" ? <Layout>
            <AutoLogout admin_status={admin_status} />
            <BasePageRoute />
          </Layout>
            : <AdminLayout>
              <AutoLogout admin_status={admin_status} />
              <AdminPageRoute />
            </AdminLayout>}
        </>
        )}
      </Switch>
    </Suspense>

  );
}
